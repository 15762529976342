import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Noindex from "../components/noindex"
import Helmet from "react-helmet"

const PrivacyPage = (location) => {
	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": "プライバシーポリシー",
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	return (
		<Layout>
			<div className="section" style={{padding: `0.5rem 1rem`}}>
				<div className="container">
					<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`}}>
						<ul>
							<li><Link to="/">トップ</Link></li>
							<li className="is-active"><Link to="" aria-current="page">プライバシーポリシー</Link></li>
						</ul>
					</nav>
					<Helmet>
  	   			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
	   			</Helmet>
					<div className="container">
						<h2 className="title is-5" style={{marginBottom: `0.5rem`}}>プライバシーポリシー</h2>
						<hr style={{margin: `0.5rem 0`}}/>
					</div>
					<div className="content is-small">
						<p>当サイトにおける個人情報の取り扱いについて、以下のとおりにプライバシーポリシーを定めます。</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>個人情報の利用目的</h3>
						<p>当サイトでは、メールでのお問い合わせの際に、お名前（ハンドルネーム）・メールアドレス等の個人情報をご登録いただく場合があります。これらの個人情報は、質問に対する回答や必要な情報をご連絡するために利用し、それ以外の目的では利用しません。</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>個人情報の第三者への開示</h3>
						<p>個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
						<ul>
							<li>本人のご了解がある場合</li>
							<li>法令等への協力のため、開示が必要となる場合</li>
						</ul>
						<p>ご本人からの個人データの開示、訂正、削除をご希望の場合には、ご本人であることを確認したうえで、速やかに対応致します。</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>Cookieについて</h3>
						<p>当サイトでは、一部のコンテンツにおいてCookieを利用しています。Cookieとは、webコンテンツへのアクセスに関する情報であり、お名前・メールアドレス・住所・電話番号は含まれません。お使いのブラウザ設定からCookieを無効にすることが可能です。</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>アクセス解析ツールについて</h3>
						<p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。 この機能はCookieを無効にすることで収集を拒否することが出来ます。Googleアナリティクスの詳細は「Googleアナリティクス利用規約」をご覧ください。</p>
		      </div>
				</div>
			</div>
  	  <Seo title="プライバシーポリシー" canonical={location.pathname} />
  	  <Noindex />
		</Layout>			
  )
}

export default PrivacyPage
